import { useEffect, useState, useContext, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../../../context/MddContext";
import sumParser from "../../../../utils/streamStore/sumParser";
import { streamRegex } from "../../../../utils/streamStore/streamRegex";

import "../../../../styles/streamStore/detailedStream/editPackages.css";
import mddStreamStoreApi from "../../../../api/mddStreamStoreApi";
import revertingToken from "../../../../utils/auth/revertingToken";

import MddButton from "../../../reusableElements/MddButton";
import MddCustomInput from "../../../reusableElements/MddCustomInput";
import PackageClause from "../elements/PackageClause";
import LinkedStreamCard from "../elements/LinkedStreamCard";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function EditPackages({
  versionPackages,
  versionFactory,
  setVersionFactory,
  postingPackages,
  deletingPackages,
  versionPublished,
  packagesTest,
  packagesAlgorithm,
  packagesErrorMessage,
  setPackagesErrorMessage,
  packagesUploadFiles,
  setPackagesUploadFiles,
  packagesClauses,
  packagesFilesCounter,
  setPackagesFilesCounter,
  packagesFilesInputs,
  setPackagesFilesInputs,
}) {
  const {
    activeStream,
    currentTeam,
    activeVersion,
    closingSession,
    userToken,
    feedBackBuilder,
    handling401,
    activeLinked,
    setActiveLinked,
    reverseActive,
    setReverseActive,
  } = useContext(MddContext);
  let history = useHistory();
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [existingPackages, setExistingPackages] = useState([]);
  const [sizesSum, setSizesSum] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(
    packagesTest ? packagesTest : "waiting"
  );
  const [packagesAlgo, setPackagesAlgo] = useState([]);
  const [logsModalTitle, setLogsModalTitle] = useState("");
  const [selectedClause, setSelectedClause] = useState(null);
  const [algoNodes, setAlgoNodes] = useState(
    packagesAlgorithm ? packagesAlgorithm.nodes : 0
  );
  const [algoEndpoints, setAlgoEndpoints] = useState(
    packagesAlgorithm ? packagesAlgorithm.endpoints : 0
  );
  const [algoCpu, setAlgoCpu] = useState(
    packagesAlgorithm ? packagesAlgorithm.cpu : 0
  );
  const [algoDisk, setAlgoDisk] = useState(
    packagesAlgorithm ? packagesAlgorithm.disk : 0
  );
  const [linkedStreams, setLinkedStreams] = useState([]);
  const [lambdaEndpoints, setLambdaEndpoints] = useState(
    packagesAlgorithm ? packagesAlgorithm.lambdaEndpoints : 100
  );
  // const [activeLinked, setActiveLinked] = useState([]);
  // const [reverseActive, setReverseActive] = useState(null);

  const returningExistingPackages = () => {
    let stockPackages = [];
    for (let i = 0; i < versionPackages.length; i++) {
      stockPackages.push(
        <div key={`existing package ${versionPackages[i].id}`}>
          {activeStream.provider === currentTeam || currentTeam === "all" ? (
            <a
              href={`${REACT_APP_BASE_URL}/assets${versionPackages[i].url}`}
              download
              title={`télécharger ${versionPackages[i].filename}`}
            >
              {`${versionPackages[i].filename} (${versionPackages[i].size})`}
            </a>
          ) : (
            <p>{versionPackages[i].filename}</p>
          )}
          {!versionPublished && (
            <MddButton
              iconName="bi-x"
              buttonSize="small"
              smallButtonDescription="Delete package"
              buttonActionFunctionOne={deletingPackages}
              buttonActionPropOne={versionPackages[i].id}
              iconColor="altButtonColor"
            />
          )}
        </div>
      );
    }
    setExistingPackages(stockPackages);
  };

  const returningInputs = () => {
    let stockInputs = [];
    for (let i = 0; i < packagesFilesCounter; i++) {
      stockInputs.push(
        <MddCustomInput
          key={`custom input ${i}`}
          setUpperLevelFile={handlingInputChange}
          lastInput={i === packagesFilesCounter - 1}
        />
      );
    }
    setPackagesFilesInputs(stockInputs);
  };
  const handlingInputChange = (inputValue, lastInput) => {
    if (inputValue !== null) {
      lastInput && setPackagesFilesCounter(packagesFilesCounter + 1);
      let stockFiles = [...packagesUploadFiles];
      stockFiles.push(inputValue);
      setPackagesUploadFiles(stockFiles);
      setSizesSum(sizesSum + inputValue.size);
      setUploadStatus("waiting");
      setPackagesErrorMessage("");
    }
  };

  function setLabelsHeight() {
    let stockInputs = document.getElementsByClassName("inputsContainer");
    let stockLabels = document.getElementsByClassName("labelsContainer");
    if (stockInputs[0] && stockLabels[0]) {
      stockLabels[0].style.height = stockInputs[0].scrollHeight + "px";
    }
  }

  const returningClauses = () => {
    let stockAlgo = [];
    let stockClauses = [];
    let stockFlow = "";
    for (let i = 0; i < packagesClauses.length; i++) {
      if (packagesClauses[i].flow !== stockFlow) {
        stockClauses.push(
          <article className="singleClause clauseFlow">
            <p>{packagesClauses[i].flow}</p>
          </article>
        );
        stockFlow = packagesClauses[i].flow;    
      }
      stockClauses.push(
        <PackageClause
          clause={packagesClauses[i]}
          activeVersion={activeVersion}
          selectedClause={selectedClause}
          setSelectedClause={setSelectedClause}
          clauseIndex={stockClauses.length}
          key={`clause ${packagesClauses[i].uuid}`}
          logsModalTitle={logsModalTitle}
          setLogsModalTitle={setLogsModalTitle}
          postingClause={postingClause}
          setActiveLinked={setActiveLinked}
          reverseActive={reverseActive}
          updatingClauses={updatingClauses}
          lambdaEndpoints={lambdaEndpoints}
        />
      );
    }
    stockAlgo.push(
      <section className="packagesTreeContainer">
        <p>{packagesAlgorithm.solution}</p>
        <p>version : {packagesAlgorithm.version}</p>
        <p>stream : {activeVersion.reference}</p>
        <div className="treeLabelsContainer">
          <p
            style={
              activeStream &&
              activeStream.editable &&
              (activeStream.provider === currentTeam || currentTeam === "all")
                ? null
                : { width: "calc(100% - 42rem)" }
            }
          >
            Lambda
          </p>
          <p>Network</p>
          {activeStream &&
            activeStream.editable &&
            (activeStream.provider === currentTeam ||
              currentTeam === "all") && <p>Nodes</p>}
          {activeStream &&
            activeStream.editable &&
            (activeStream.provider === currentTeam ||
              currentTeam === "all") && <p>Endpoints</p>}
          
        </div>
        <div className="treeContentContainer">
          {stockClauses.length > 0 && stockClauses}
        </div>
      </section>
    );
    setPackagesAlgo(stockAlgo);
    forceUpdate();
  };

  const postingClause = (dataInput) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .post(`/version/resources/${activeVersion.id}?timeout=60000`, dataInput, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = response.data.data;
          setAlgoNodes(informations.total.nodes);
          setAlgoEndpoints(informations.total.endpoints);
          setAlgoCpu(informations.total.cpu);
          setAlgoDisk(informations.total.disk);
          feedBackBuilder(
            true,
            `Resources mises à jour`,
            "Streams Store"
          );
          if (informations.clauses.length > 0) {
            // setPackagesAlgo([]);
            updatingClauses(informations.clauses);
          }
        })
        .catch((err) => {
          
          switch(err.response.status) {
            case 401:
              handling401(
                err,
                history,
                `Vous devez être connecté`
              );
              break;
            case 409:
              //console.log(JSON.stringify(err.response.data));
              if (err.response.data.error.code==="NODES QUOTA EXCEEDED") {
                feedBackBuilder(
                  false,
                  `Vous avez atteint le nombre maximal de nodes autorisés (nodes=${err.response.data.error.count}, quota=${err.response.data.error.limit})`,
                  "Quotas"
                );  
              }
              if (err.response.data.error.code==="ENDPOINTS QUOTA EXCEEDED") {
                feedBackBuilder(
                  false,
                  `Vous avez atteint le nombre maximal d'endpoints autorisés (endpoints=${err.response.data.error.count}, quota=${err.response.data.error.limit})`,
                  "Quotas"
                );  
              }
              
              break;              
          }         
        });
    }
  };

  const updatingClauses = (clausesInput) => {
    //console.log(clausesInput);
    // let stockClauses = [...packagesClauses];
    // let stockIndex = null;
    // let stockClause = null;
      
    // contournement Bug de mise à jour de l'état ReactJS par injection des valeurs directement dans la vue   
    for (let i = 0; i < clausesInput.length; i++) {
      let clauseDetails = document.getElementById( `clause-${clausesInput[i].uuid}` ).childNodes;
      let clause = clausesInput[i];  
      let nodesText = clauseDetails[2];   
      let endpointsText = clauseDetails[3];
      
    
      //alert(JSON.stringify(clause));
        
      if (clause.local) {
          endpointsText.innerText = '';
          nodesText.innerText = '';
      }
      else {
          endpointsText.innerText = clause.nodes.length==0 ? clause.endpoints : clause.nodes.length * clause.endpoints;
          nodesText.innerText = clause.nodes.length;
      }
     
        
    //clauseDetails[2].innerText =
    //clausesInput[i].nodes.length > 0
    //      ? clausesInput[i].endpoints * clausesInput[i].nodes.length
    //      : clausesInput[i].endpoints;
    //  clauseDetails[3].innerText = clausesInput[i].nodes.length;
        
        
      
    }
    // setPackagesClauses([...stockClauses]);
  };

  const retrievingLinkedStreams = () => {
    let stockCards = [];
    let sources = {};
    let links = {};
    for (let i = 0; i < packagesClauses.length; i++) {
      if (packagesClauses[i].source && !sources[packagesClauses[i].source.reference]) {
         sources[packagesClauses[i].source.reference] = "registered"; 
         stockCards.push(
            <LinkedStreamCard
              activeLinked={activeLinked}
              stream={packagesClauses[i].source}
              setReverseActive={setReverseActive}
              status="source"
            />
         ); 
      }
      if (packagesClauses[i].linked) {
        for (let j = 0; j < packagesClauses[i].linked.length; j++) {  
            if (!links[packagesClauses[i].linked[j].reference]) {
                 links[packagesClauses[i].linked[j].reference] = "registered"; 
                 stockCards.push(
                    <LinkedStreamCard 
                      activeLinked={activeLinked}    
                      stream={packagesClauses[i].linked[j]}
                      setReverseActive={setReverseActive}
                      status="linked" 
                    />
                );
            }
        }        
      }    
    }
        
    setLinkedStreams([...stockCards]);
  };
    
  const retrievingLinkedStreams_ = () => {
    let stockStreams = [];
    for (let i = 0; i < packagesClauses.length; i++) {
      if (packagesClauses[i].linked) {
        for (let j = 0; j < packagesClauses[i].linked.length; j++) {
          if (!stockStreams.includes(packagesClauses[i].linked[j])) {
            stockStreams.push(packagesClauses[i].linked[j]);
          }
        }
      }
    }
    let stockCards = [];
    for (let k = 0; k < stockStreams.length; k++) {
      stockCards.push(
        <LinkedStreamCard stream={stockStreams[k]} status="linked" />
      );
    }
    let stockSources = [];
    for (let i = 0; i < packagesClauses.length; i++) {
      if (packagesClauses[i].source) {
        if (!stockSources.includes(packagesClauses[i].source)) {
          stockSources.push(packagesClauses[i].source);
        }
      }
    }
    for (let k = 0; k < stockSources.length; k++) {
      stockCards.push(
        <LinkedStreamCard
          activeLinked={activeLinked}
          stream={stockSources[k]}
          setReverseActive={setReverseActive}
          status="source"
        />
      );
    }
    setLinkedStreams([...stockCards]);
  };

  useEffect(() => {
    setPackagesFilesInputs([]);
    returningInputs();
  }, [packagesFilesCounter]);

  useEffect(() => {
    versionPackages &&
      versionPackages.length > 0 &&
      returningExistingPackages();
  }, [versionPackages, versionFactory]);

  useEffect(() => {
    packagesTest && setUploadStatus(packagesTest);
  }, [packagesTest]);

  useEffect(() => {
    uploadStatus === "uploading" &&
      uploadProgress < 100 &&
      setUploadProgress(uploadProgress + 1);
  }, [uploadStatus, uploadProgress]);

  useEffect(() => {
    activeVersion && setLabelsHeight();
  });
  useEffect(() => {
    setPackagesFilesCounter(1);
  }, [versionPackages]);

  useEffect(() => {
      
    if (packagesAlgorithm) {
       // console.log(packagesAlgorithm);
      setAlgoNodes(packagesAlgorithm.nodes);
      setAlgoEndpoints(packagesAlgorithm.endpoints);
      setAlgoCpu(packagesAlgorithm.cpu);
      setAlgoDisk(packagesAlgorithm.disk);
    }
  }, [packagesAlgorithm]);

  useEffect(() => {
    packagesClauses && packagesClauses.length > 0 && returningClauses();
    packagesClauses && packagesClauses.length > 0 && retrievingLinkedStreams();
  }, [packagesClauses]);

  return (
    <div className="packagesGlobalContainer">
      {activeStream &&
        activeStream.editable &&
        (activeStream.provider === currentTeam || currentTeam === "all") &&
        (activeVersion ? (
          <section className="packagesImportContainer">
            <div className="labelsContainer">
              <label htmlFor="factoryForm">Plugin</label>
              <p>Packages</p>
            </div>
            <div className="inputsContainer" id="inputsContainer">
              {versionPublished ? (
                <p>
                  {versionFactory ? versionFactory : "Aucune factory spécifiée"}
                </p>
              ) : (
                <input
                  type="text"
                  id="factoryForm"
                  className={
                    streamRegex.factory.test(versionFactory) ||
                    versionFactory.length === 0
                      ? ""
                      : "incorrectInput"
                  }
                  title={
                    streamRegex.factory.test(versionFactory) ||
                    `Entrez le FQN de la classe d'implémentation, ex: 'org.my.business.stream.Analytics`
                  }
                  placeholder="factory"
                  value={versionFactory}
                  onChange={(e) => {
                    setVersionFactory(e.target.value);
                    setUploadStatus("waiting");
                    setPackagesErrorMessage("");
                  }}
                  onBlur={() => {
                    packagesUploadFiles.length > 0 && postingPackages();
                  }}
                  onKeyUp={(e) => {
                    (e.key === "Enter" || e.keyCode === 13) &&
                      packagesUploadFiles.length > 0 &&
                      postingPackages();
                  }}
                />
              )}
              {versionPackages &&
                versionPackages.length > 0 &&
                existingPackages}
              {versionPublished && versionPackages.length === 0 && (
                <p>Aucun package spécifié</p>
              )}
              {versionPublished || packagesFilesInputs}
            </div>
          </section>
        ) : (
          <article className="importStatusContainer">
            <p>
              Pour ajouter des packages externes, veuillez d'abord créer une
              première version du stream en allant dans [général] et cliquer sur
              le bouton [Créer la première version] du formulaire. Entrez un
              numéro de version et éventuellement une description.
            </p>
          </article>
        ))}
      {packagesAlgo.length > 0 && (
        <section className="importStatusContainer">
          {(uploadStatus === "waiting" || uploadStatus === "uploading") && (
            <p>Taille des fichiers sélectionnés : {sumParser(sizesSum)}</p>
          )}
          {uploadStatus === "finished" && <p>Votre stream est prêt</p>}
          {(uploadStatus === "unfinished" ||
            uploadStatus === "failed" ||
            uploadStatus === "required" ||
            uploadStatus === "successful") && (
            <p
              className={packagesTest === "failed" ? "statusAlert" : "statusOk"}
            >
              {packagesErrorMessage}
            </p>
          )}
        </section>
      )}
      {packagesAlgo.length > 0 && (
        <section className="sumedupRessourcesContainer">
          <p>
            <span>{ algoNodes }</span>
            {`
        nodes`}
          </p>
          <p>
            <span>{ algoEndpoints }</span>
            {`
        endpoints`}
          </p>
          <p>
            <span>{algoCpu}</span>
            {`
        GHz`}
          </p>
          <p>
            <span>{algoDisk}</span>
            {`
        To`}
          </p>
        </section>
      )}
      {packagesAlgo.length > 0 && packagesAlgo}
      {linkedStreams.length > 0 && (
        <section className="linkedStreamsContainer">{linkedStreams}</section>
      )}
    </div>
  );
}
