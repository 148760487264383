export default function EndpointsModal({
  endpointsModal,
  clause,
  clauseEndpoints,
  lambdaEndpoints,
  setClauseEndpoints,
  setEndpointsModal,
  postingClause,
}) {
  return (
    <article
      className={`endpointsModal${
        endpointsModal ? " openedEndpointsModal" : ""
      }`}
      id={`endpointsModal - ${clause.uuid}`}
    >
      <label
        htmlFor={`endpointsInput - ${clause.uuid}`}
        className="endpointsModalLabel"
      >
        {`Endpoints par node : ${clauseEndpoints}`}
      </label>
      <input
        type="range"
        id={`endpointsInput - ${clause.uuid}`}
        className="endpointsModalInput"
        name={`endpointsInput - ${clause.uuid}`}
        min="0"
        max={lambdaEndpoints}
        value={clauseEndpoints}
        onChange={(e) => {
          clause.endpoints = e.target.value;
          setClauseEndpoints(e.target.value);
        }}
        onMouseUp={(e) => {
          setTimeout(() => {
            setEndpointsModal(false);
          }, 2000);
          postingClause({ clause: clause.uuid, endpoints: e.target.value });
        }}
      />
    </article>
  );
}
